<template>
  <div>
    <template v-if="!isMobile" ref="scrollComponent">
      <div class="match-wrapper" v-for="(o, index) in matchList" :key="index" v-show="!isLoading && !isShowNoData">
        <template v-if="type === 'fav'">
          <div class="display-flex-align-center home-match__title-wrapper capitalize">{{ o.matchDate }}</div>  
          <template v-for="l in o.leagueMatchList" :key="l.leagueId">
            <router-link
              :to="{
                path: `/${currentLocale}/${currentSportType}/${l.countryKey}/${l.leagueKey}`,
                query: { leagueId: l.leagueId, subLeagueId: l.subLeagueId },
              }"
              class="display-flex-align-center home-match__title-wrapper"
            >
              <div class="home-match__title">{{ l.country }}</div>
              <span class="home-match__title" v-if="l.leagueName !== null">|</span>
              <div class="home-match__title" v-if="l.leagueName !== null">{{ l.leagueName }}</div>
			        <div v-if="l.isFavLeague" class="icon-fav small" :class="{ 'has-subleague-fav': !!l.subLeagueName}"></div>
              <div class="home-match__title" v-if="!!l.subLeagueName">: {{ l.subLeagueName }}</div>
              
              <div class="ml-s">
                <svg width="6" height="10" viewBox="0 0 6 10" class="home-match__title-icon" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"/>
                </svg>
              </div>
            </router-link>
            <router-link
              :to="{
                path: `/${currentLocale}/${currentSportType}/${l.countryKey}/${
                  l.leagueKey
                }/${level2.opponents[0].name.replace(/(\s|\/)/g, '-') +
                  '-vs-' +
                  level2.opponents[1].name.replace(/(\s|\/)/g, '-')}/${
                  level2.matchId
                }`,
              }"
              target="_blank"
              class="match-container"
              v-for="(level2, index) in l.matchList"
              :key="index"
            >
              <!-- <div>{{ level2.opponents[0].name }}</div> -->
              <div class="home-match__score-card__wrapper" :class="{ 'live-selected': level2.isLive }">
                <div class="btn-fav-wrapper" @click="handleFavMatchClick(o, level2, $event)">
                <div class="btn-fav"  :class="favMatchClassObj(level2)"></div>
                </div>
                <div class="home-match__status">
                  <!--  class="font-s" -->
                  <span>{{ level2.matchStatus }}</span>
                </div>
                <div class="home-match__1-wrapper">
                  <div class="display-flex home-match__1-container"  v-for="(level3, index) in level2.opponents" :key="index">
                    <div class="display-flex-center font-s">
                      <div class="icon-league home-match__icon-team">
                        <img :src="`${s3ImgUrl}/${level3.logo}`" />
                      </div>
                      <span class="mr-03rem home-match__team-name">{{ level3.name }}</span>
                      <span v-if="level3.isFavTeam" class="icon-fav tiny mr-03rem"></span>                      
                      <span class="penalty-compact-label" v-if="level3.penaltyWin">P</span>
                      <!-- <img class="img-contain" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png"/> -->
                    </div>
                    <div>
						        <div class="match-detail__score-wrapper font-s">
                      <span class="score">{{ level3.score }}</span>
                      <span class="penalty-score score" v-if="level3.penaltyScore != null">{{ level3.penaltyScore }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="home-match__actions-card__wrapper">
                <div
                  class="home-match__icon-wrapper"
                  @mouseenter="showStatsPopover(level2)"
                  @mouseleave="hideStatsPopover(level2)"
                  @click.prevent
                  v-show="false"
                >
                  <div class="home-match__icon-stats" :class="{ selected: level2.isShowStatsPopover }" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="14px"
                      height="14px"
                      viewBox="0 0 14 14"
                      version="1.1"
                    >
                      <path
                        style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
                        d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
                      />
                    </svg>
                  </div>
                  <StatsContainerPopover :matchId="level2.matchId" v-if="level2.isShowStatsPopover"></StatsContainerPopover>
                </div>
                <div v-if="level2.containLiveStream" class="home-match__icon-wrapper">
                  <div class="home-match__icon-livevideo" @click="handleHighlight(o, level2, $event)"></div>
                </div>
                <div v-else class="home-match__icon-wrapper">
                  <div
                    class="home-match__icon-highlight"
                    :class="{ selected: level2.containHighlight }"
                    @click="handleHighlight(o, level2, $event)"
                  ></div>
                </div>
              </div>
              <div
                class="home-match__static-card__wrapper"
                @click="handleRedirect(o, level2, $event)"
                @mouseenter="handleShowOddsDetailHover(o, level2, $event)"
                @mouseleave="handleShowOddsDetailLeave(o, level2, $event)"
              >
                <OddsMatchContentOverview
                  :oddsMatchData="level2.oddsMatchOverview"
                  :isShowBriefData="oddsMatch.isShowBriefData"
                ></OddsMatchContentOverview>
                <OddsMatchContentDetail
                  v-if="level2.isShowOddsDetail"
                  :oddsMatchData="oddsMatch"
                ></OddsMatchContentDetail>
              </div>
            </router-link>
          </template>
        </template>
        <template v-else>
          <div v-if="type==='league'" class="home-match__date">{{ o.matchDate }}</div>
            <!-- <router-link
              v-else
                :to="{
                path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
                query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId },
                }"
                class="display-flex-align-center home-match__title-wrapper"
              > -->
            <div v-else class="display-flex-align-center home-match__title-wrapper">
              <div class="home-match__title">{{ o.country }}</div>
              <span class="home-match__title" v-if="o.leagueName !== null">|</span>
              <div class="home-match__title" v-if="o.leagueName !== null">{{ o.leagueName }}</div>
              <div v-if="o.isFavLeague" class="icon-fav small" :class="{ 'has-subleague-fav': !!o.subLeagueName}"></div>
              <div class="home-match__title" v-if="!!o.subLeagueName">: {{ o.subLeagueName }}</div>
              <div class="ml-s">
              <!-- <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                class="home-match__title-icon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"
                />
              </svg> -->
              </div>
            </div>
            <!-- </router-link> -->
            <router-link
              :to="{ path: `/${currentLocale}/${currentSportType}/${o.countryKey ?? countryKey}/${o.leagueKey ?? leagueKey}/${level2.opponents[0].name.replace(/(\s|\/)/g, '-') + '-vs-' + level2.opponents[1].name.replace(/\s|\//g, '-')}/${ level2.matchId }`,}"
              class="match-container"
              v-for="(level2, index) in o.matchList"
              :key="index"
            >
              <!-- <div>{{ level2.opponents[0].name }}</div> -->
              <div class="home-match__score-card__wrapper" :class="{ 'live-selected': level2.isLive }">
              <!-- <div class="btn-fav-wrapper" @click="handleFavMatchClick(o, level2, $event)">
                <div class="btn-fav"  :class="favMatchClassObj(level2)"></div>
              </div> -->
              <div class="home-match__status">
                <!-- class="font-s" -->
                <span v-show="type === 'team'" v-html="level2.matchStatus"></span>
                <!-- class="font-s" -->
                <span v-show="type !== 'team'">{{ level2.matchStatus }}</span>
              </div>
              <div class="home-match__1-wrapper">
                <div class="display-flex home-match__1-container" v-for="(level3, index) in level2.opponents" :key="index">
                <div class="display-flex-center font-s">
                  <div class="icon-league home-match__icon-team">
                  <img :src="`${s3ImgUrl}/${level3.logo}`" />
                  </div>
                  <span class="mr-03rem home-match__team-name">{{ level3.name }}</span>
                  <span v-if="level3.isFavTeam" class="icon-fav tiny mr-03rem"></span>
                  <span class="penalty-compact-label" v-if="level3.penaltyWin">P</span>
                  <!-- <img class="img-contain" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png"/>               -->
                </div>
                <div>
                  <div class="match-detail__score-wrapper font-s">
                    <span class="score">{{ level3.score }}</span>
                    <span class="penalty-score score" v-if="level3.penaltyScore != null">{{ level3.penaltyScore }}</span>
                  </div>
                </div>
                </div>
              </div>
              </div>
              <div class="home-match__actions-card__wrapper">
              <!-- <div class="home-match__icon-match"></div> -->
              <!-- @click.prevent="toggleStatsPopover(level2)" -->
              <div
                class="home-match__icon-wrapper"
                @mouseenter="showStatsPopover(level2)"
                @mouseleave="hideStatsPopover(level2)"
                @click.prevent
                v-show="false"
              >
                <div class="home-match__icon-stats" :class="{ selected: level2.isShowStatsPopover }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14px"
                  height="14px"
                  viewBox="0 0 14 14"
                  version="1.1"
                >
                  <path
                  style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
                  d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
                  />
                </svg>
                </div>
                <StatsContainerPopover :matchId="level2.matchId" v-if="level2.isShowStatsPopover"></StatsContainerPopover>
              </div>
              <!-- v-if="level2.containLiveStream" -->
              <div class="home-match__icon-wrapper">
                <div v-if="level2.containLiveStream" class="home-match__icon-livevideo" @click="handleHighlight(o, level2, $event)"></div>
                <div v-else class="home-match__icon-no-livevideo"></div>
              </div>
              <!-- <div v-else class="home-match__icon-wrapper">
                <div
                class="home-match__icon-highlight"
                :class="{ selected: level2.containHighlight }"
                @click="handleHighlight(o, level2, $event)"
                ></div>
              </div> -->
              </div>
              <div
                class="home-match__static-card__wrapper"
                @click="handleRedirect(o, level2, $event)"
                @mouseenter="handleShowOddsDetailHover(o, level2, $event)"
                @mouseleave="handleShowOddsDetailLeave(o, level2, $event)"
              >
                <template v-if="(type === 'team' || type === 'league') && level2.oddsMatchShowBriefData">
                  <OddsMatchContentOverview
                    :oddsMatchData="level2.oddsMatchOverview"
                    :isShowBriefData="level2.oddsMatchShowBriefData"
                  ></OddsMatchContentOverview>
                </template>

                <template v-else-if="(type === 'team' || type === 'league') && !level2.oddsMatchShowBriefData">
                  <OddsMatchContentOverview
                    :oddsMatchData="level2.oddsMatchOverview"
                    :isShowBriefData="level2.oddsMatchShowBriefData"
                  ></OddsMatchContentOverview>
                  <OddsMatchContentDetail
                    v-if="level2.isShowOddsDetail"
                    :oddsMatchData="oddsMatch"
                  ></OddsMatchContentDetail>
                </template>

                <template v-else>
                  <OddsMatchContentOverview
                    :oddsMatchData="level2.oddsMatchOverview"
                    :isShowBriefData="oddsMatch.isShowBriefData"
                  ></OddsMatchContentOverview>
                  <OddsMatchContentDetail
                    v-if="level2.isShowOddsDetail"
                    :oddsMatchData="oddsMatch"
                  ></OddsMatchContentDetail>
                </template>
              </div>
            </router-link>
            </template>
          </div>
    </template>

    <template v-if="isMobile">
      <div class="home-match__wrapper" v-for="(o, index) in matchList" :key="index" v-show="!isLoading && !isShowNoData">
        <template v-if="type === 'fav'">
          <div class="display-flex-align-center home-match__title-wrapper capitalize">
            {{ o.matchDate }}
          </div>
          <template v-for="l in o.leagueMatchList" :key="l.leagueId">
            <div class="display-flex-align-center home-match__title-wrapper">
              <Icon
                :logoUrl="l.logo"
                class="mr-05rem home-match__title-flag"
              ></Icon>
              <div class="home-match__title-container">
                <router-link
                  :to="{
                    path: `/${currentLocale}/${currentSportType}/${l.countryKey}/${l.leagueKey}`,
                    query: { leagueId: l.leagueId, subLeagueId: l.subLeagueId },
                  }"
                  class="home-match__title home-match__title-mobile"
                >
                  <span v-if="!!l.leagueName">{{ l.leagueName }}</span>
				          <span v-if="l.isFavLeague" class="icon-fav small has-subleague-fav"></span>
                  <span v-if="l.leagueName && l.subLeagueName" class="has-subleague-fav"> : </span>
                  <span v-if="!!l.subLeagueName">{{ l.subLeagueName }}</span>
                 
                </router-link>
                <router-link
                  v-if="!!l.leagueName"
                  :to="{
                    path: `/${currentLocale}/${currentSportType}/${l.countryKey}/${l.leagueKey}`,
                    query: { leagueId: l.leagueId, subLeagueId: l.subLeagueId },
                  }"
                  class="home-match__title-country"
                  >{{ l.country }}</router-link
                >
                <router-link
                  v-else
                  :to="{
                    path: `/${currentLocale}/${currentSportType}/${l.countryKey}/${l.leagueKey}`,
                    query: { leagueId: l.leagueId, subLeagueId: l.subLeagueId },
                  }"
                  class="home-match__title home-match__title-mobile"
                  >{{ l.country }}</router-link
                >
              </div>

              <div class="ml-s">
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  class="home-match__title-icon"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"
                  />
                </svg>
              </div>
            </div>
            <div class="home-match__container">
              <router-link
                :to="{
                  path: `/${currentLocale}/${currentSportType}/${
                    l.countryKey
                  }/${l.leagueKey}/${level2.opponents[0].name.replace(/(\s|\/)/g, '-') +
                    '-vs-' +
                    level2.opponents[1].name.replace(/(\s|\/)/g, '-')}/${
                    level2.matchId
                  }`,
                }"
                target="_blank"
                class="home-match__card home-match__card-odds"
                :class="{
                  'home-match__card__status-top': isShowStatusAtTop,
                  'live-selected': level2.isLive,
                }"
                v-for="(level2, index) in l.matchList"
                :key="index"
                @click="$tools.openNewWindow($event)"
              >
                <div class="display-flex-center w-100">
                  <div class="btn-fav-wrapper"  @click="handleFavMatchClick(o, level2, $event)">
                    <div class="btn-fav" :class="favMatchClassObj(level2)"></div>
                  </div>
                  <div class="w-100">
                    <div class="display-flex w-100">
                      <div class="home-match__card-odds-content">
                        <div class="home-match__detail" v-for="(level3, index) in level2.opponents" :key="index">
                          <div class="home-match__detail-opponent">
							<Icon :logoUrl="level3.logo" class="home-match__icon"></Icon>
							<span 
                class="mr-03rem home-match__team-name"
                :class="{
                  'with-fav-star': level3.isFavTeam, 
                  'with-penalty-score': level3.penaltyScore != null,
                  'with-penalty-win': level3.penaltyWin
                }"
              >{{ level3.name }}</span>
							<span class="icon-fav tiny mr-03rem" v-if="level3.isFavTeam" ></span>
              <span class="penalty-compact-label" v-if="level3.penaltyWin">P</span>
							<!-- <img class="ml-1rem" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png" /> -->
                          </div>

                          <div class="match-detail__score-wrapper">
                            <span class="score">{{ level3.score }}</span>
                            <span class="penalty-score score" v-if="level3.penaltyScore != null">{{ level3.penaltyScore }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-show="isShowWinLose">
                    	<div class="home-match__win-lose" :class="level2.winLose">{{ level2.winLose }}</div>                      
                      </div>

                      <div class="home-match__static-card__wrapper" @click="handleShowOddsDetailMobile(o, level2, $event)">
                        <OddsMatchContentOverview
                          :team="level3"
                          :oddsMatchData="level2.oddsMatchOverview"
                          :isShowBriefData="oddsMatch.isShowBriefData"
                        ></OddsMatchContentOverview>
                      </div>
                    </div>
                    <div class="home-match__card-odds-bottom">
                      <div class="home-match__card-odds-time" :class="{ 'match-detail__status-w-auto': isStatusWidthAuto, }">
                        <span :class="{ 'match-status__live': level2.matchStatus === 'Live', }">{{ level2.matchStatus }}</span>
                      </div>
                      <div class="display-flex-center">
                        <div class="home-match__icon-wrapper" @click="showStatsPopoverMobile(level2, $event)">
                          <div class="home-match__icon-stats">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="14px"
                              height="14px"
                              viewBox="0 0 14 14"
                              version="1.1"
                            >
                              <path style="stroke: none;fill-rule: nonzero;fill-opacity: 1;"
                                d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
                              />
                            </svg>
                          </div>
                        </div>
                        <div v-if="level2.containLiveStream" class="home-match__icon-wrapper">
                          <div class="home-match__icon-livevideo__mobile" @click="handleHighlight(o, level2, $event)"></div>
                        </div>
                        <div v-else class="home-match__icon-wrapper">
                          <div class="home-match__icon-highlight__mobile" :class="{ selected: level2.containHighlight }" @click="handleHighlight(o, level2, $event)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </template>
        </template>
        <template v-else>
			<div v-if="type==='league'" class="home-match__date">{{ o.matchDate }}</div>
			<div v-else class="display-flex-align-center home-match__title-wrapper">
				<Icon :logoUrl="o.logo" class="mr-05rem home-match__title-flag"></Icon>
				<div class="home-match__title-container">
				<!-- <router-link
					:to="{
					path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
					query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId },
					}"
					class="home-match__title home-match__title-mobile"
				> -->
        <div class="home-match__title home-match__title-mobile">
					<span v-if="!!o.leagueName">{{ o.leagueName }}</span>
					<span class="icon-fav small has-subleague-fav"  v-if="o.isFavLeague"></span>
					<span class="has-subleague-fav" v-if="o.leagueName && o.subLeagueName"> : </span>
					<span v-if="!!o.subLeagueName">{{ o.subLeagueName }}</span>
				</div>
				<!-- </router-link> -->
				<!-- <router-link
					v-if="!!o.leagueName"
					:to="{
					path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
					query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId },
					}"
					class="home-match__title-country"
					>{{ o.country }}</router-link
				>
				<router-link
					v-else
					:to="{
					path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
					query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId },
					}"
					class="home-match__title home-match__title-mobile"
					>{{ o.country }}</router-link
				> -->
        <div class="home-match__title-country">{{ o.country }}</div>
				</div>

				<div class="ml-s">
				<!-- <svg
					width="6"
					height="10"
					viewBox="0 0 6 10"
					class="home-match__title-icon"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
					d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"
					/>
				</svg> -->
				</div>
			</div>
			<div class="home-match__container">
        <!-- @click="$tools.openNewWindow($event)" -->
				<router-link
				:to="{
					path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${
					o.leagueKey
					}/${level2.opponents[0].name.replace(/(\s|\/)/g, '-') +
					'-vs-' +
					level2.opponents[1].name.replace(/(\s|\/)/g, '-')}/${
					level2.matchId
					}`,
				}"
				class="home-match__card home-match__card-odds"
				:class="{
					'home-match__card__status-top': isShowStatusAtTop,
					'live-selected': level2.isLive,
				}"
				v-for="(level2, index) in o.matchList"
				:key="index"
				>
				<div class="display-flex-center w-100">
					<!-- <div class="btn-fav-wrapper" :class="{'btn-fav-wrapper__mobile': isLogin}" @click="handleFavMatchClick(o, level2, $event)">
						<div class="btn-fav"  :class="favMatchClassObj(level2)"></div>
					</div> -->
          <div class="home-match__card-odds-time" :class="{'longer': type === 'team'}" v-show="!isLogin">
            <span :class="{'match-status__live': level2.matchStatus === 'Live', }" v-show="type === 'team'" v-html="level2.matchStatus"></span>
            <span :class="{'match-status__live': level2.matchStatus === 'Live', }" v-show="type !== 'team'">{{ level2.matchStatus }}</span>
          </div>
					<div class="w-100">
					<div class="w-100" :class="[isLogin ? 'display-flex' : 'display-flex-align-center']">
						<div class="home-match__card-odds-content"> 
						<div class="home-match__detail" v-for="(level3, index) in level2.opponents" :key="index">
							<div class="home-match__detail-opponent">
							<Icon :logoUrl="level3.logo" class="home-match__icon"></Icon>
							<span 
                class="mr-03rem home-match__team-name"
                :class="{
                  'with-fav-star': level3.isFavTeam, 
                  'with-penalty-score': level3.penaltyScore != null,
                  'with-penalty-win': level3.penaltyWin
                }"
              >{{ level3.name }}</span>
              <span class="icon-fav tiny mr-03rem" v-if="level3.isFavTeam"></span>
              <span class="penalty-compact-label" v-if="level3.penaltyWin">P</span>
							<!-- <img class="ml-1rem"  v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png"/> -->
							</div>
							<div class="match-detail__score-wrapper">
                <span class="score"> {{ level3.score }}</span>
                <span class="penalty-score score" v-if="level3.penaltyScore != null">{{ level3.penaltyScore }}</span>
							</div>
						</div>
						</div>
						<div v-show="isShowWinLose">
						<div class="home-match__win-lose" :class="level2.winLose">{{ level2.winLose }}</div>
						</div>

            <!--  v-if="level2.containLiveStream && !isLogin" -->
            <div class="home-match__icon-wrapper">
							<div v-if="level2.containLiveStream" class="home-match__icon-livevideo__mobile" @click="handleHighlight(o, level2, $event)"></div>
              <div v-else class="home-match__icon-no-livevideo__mobile"></div>
						</div>
						<!-- <div v-else-if="!level2.containLiveStream && !isLogin" class="home-match__icon-wrapper">
							<div
							class="home-match__icon-highlight__mobile"
							:class="{ selected: level2.containHighlight }"
							@click="handleHighlight(o, level2, $event)"
							></div>
						</div> -->

						<div class="home-match__static-card__wrapper 2" @click="handleShowOddsDetailMobile(o, level2, $event)">
              <template v-if="(type === 'team' || type === 'league') && level2.oddsMatchShowBriefData">
                <OddsMatchContentOverview
                  :team="level3"
                  :oddsMatchData="level2.oddsMatchOverview"
                  :isShowBriefData="level2.oddsMatchShowBriefData"
                ></OddsMatchContentOverview>
                <OddsMatchContentDetail
                  :team="level3"
                  v-if="level2.isShowOddsDetail"
                  :oddsMatchData="oddsMatch"
                ></OddsMatchContentDetail>
              </template>

              <template v-else>
                <OddsMatchContentOverview
                  :team="level2.oddsMatchOverview"
                  :oddsMatchData="level2.oddsMatchOverview"
                  :isShowBriefData="oddsMatch.isShowBriefData"
                ></OddsMatchContentOverview>
                <OddsMatchContentDetail
                  v-if="level2.isShowOddsDetail"
                  :team="level2.oddsMatchOverview"
                  :oddsMatchData="oddsMatch"
                ></OddsMatchContentDetail>
              </template>
						</div>
					</div>
					<div  v-show="false">
						<div class="home-match__card-odds-time" :class="{ 'match-detail__status-w-auto': isStatusWidthAuto,}">
						<span :class="{'match-status__live': level2.matchStatus === 'Live', }" v-show="type === 'team'" v-html="level2.matchStatus"></span>
            <span :class="{'match-status__live': level2.matchStatus === 'Live', }" v-show="type !== 'team'">{{ level2.matchStatus }}</span>
						</div>
						<div class="display-flex-center">
						<!-- <div class="home-match__icon-match__mobile"></div> -->
						<div class="home-match__icon-wrapper" @click="showStatsPopoverMobile(level2, $event)">
							<div class="home-match__icon-stats">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="14px"
								height="14px"
								viewBox="0 0 14 14"
								version="1.1"
							>
								<path
								style="stroke: none;fill-rule: nonzero;fill-opacity: 1;"
								d="M 0.46875 4.386719 L 3.269531 4.386719 L 3.269531 13.535156 L 0.46875 13.535156 Z M 5.695312 0.46875 L 8.308594 0.46875 L 8.308594 13.535156 L 5.695312 13.535156 Z M 10.921875 7.933594 L 13.535156 7.933594 L 13.535156 13.535156 L 10.921875 13.535156 Z M 10.921875 7.933594 "
								/>
							</svg>
							</div>
						</div>
						<div v-if="level2.containLiveStream" class="home-match__icon-wrapper">
							<div class="home-match__icon-livevideo__mobile" @click="handleHighlight(o, level2, $event)"></div>
						</div>
						<div v-else class="home-match__icon-wrapper">
							<div
							class="home-match__icon-highlight__mobile"
							:class="{ selected: level2.containHighlight }"
							@click="handleHighlight(o, level2, $event)"
							></div>
						</div>
						</div>
					</div>
					</div>
				</div>
				</router-link>
			</div>
        </template>
      </div>
    </template>

    <div v-show="isLoading && !isShowNoData" class="skeleton-match__wrapper">
      <div class="mb-2rem">
        <div class="skeleton-child skeleton-match__title"></div>
        <div class="skeleton-child skeleton-match__content"></div>
        <div class="skeleton-child skeleton-match__content"></div>
      </div>

      <div class="mb-2rem">
        <div class="skeleton-child skeleton-match__title"></div>
        <div class="skeleton-child skeleton-match__content"></div>
      </div>

      <div class="mb-2rem">
        <div class="skeleton-child skeleton-match__title"></div>
        <div class="skeleton-child skeleton-match__content"></div>
        <div class="skeleton-child skeleton-match__content"></div>
      </div>

      <div class="mb-2rem">
        <div class="skeleton-child skeleton-match__title"></div>
        <div class="skeleton-child skeleton-match__content"></div>
      </div>

      <div class="mb-2rem">
        <div class="skeleton-child skeleton-match__title"></div>
        <div class="skeleton-child skeleton-match__content"></div>
        <div class="skeleton-child skeleton-match__content"></div>
      </div>

      <div class="mb-2rem">
        <div class="skeleton-child skeleton-match__title"></div>
        <div class="skeleton-child skeleton-match__content"></div>
        <div class="skeleton-child skeleton-match__content"></div>
      </div>
    </div>
    <!-- <Loading v-show="isLoading && !isShowNoData"></Loading> -->
    <div class="no-data" v-show="!isLoading && isShowNoData">
		<template v-if="type === 'fav'">
			<!-- <FavCardMatch /> -->
		</template>
		<template v-else>
			{{ $t("NO_MATCHES") }}
		</template>
    </div>
    <ModalTransparent ref="modalPopupRef">
		<OddsMatchContentDetail
		:oddsMatchData="oddsMatch"
		@click="handleRedirectMobile()"
		></OddsMatchContentDetail>
    </ModalTransparent>
    <ModalTransparent ref="statsTableModalPopupRef">
    	<StatsContainerPopover :matchId="selectedMatchId"></StatsContainerPopover>
    </ModalTransparent>

	<PopUpSignInIndexMobile ref="PopUpSignInIndexMobileRef" :message="$i18n.t('FAV_LOGIN_TIPS')" ></PopUpSignInIndexMobile>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import OddsMatchContentDetail from "@/components/match/OddsMatchContentDetail.vue";
import OddsMatchContentOverview from "@/components/match/OddsMatchContentOverview.vue";
import StatsContainerPopover from "@/components/match/StatsContainerPopover.vue";
import ModalTransparent from "@/components/modal/ModalTransparent.vue";
import FavCardMatch from "@/views/fav/components/FavCardMatch.vue";

import PopUpSignInIndexMobile from "@/components/user/PopUpSignInIndexMobile.vue"

import moment from "moment";
import config from "@/js/config.js";

import FavLoginTips from "@/components/favourite/TipsLogin.vue";

export default {
  components: {
    OddsMatchContentDetail,
    OddsMatchContentOverview,
    StatsContainerPopover,
    ModalTransparent,
    FavCardMatch,

	PopUpSignInIndexMobile
  },

  props: {
    matchObj: {
      type: Array,
    },
    isShowFav: {
      type: Boolean,
    },
    isShowWinLose: {
      type: Boolean,
    },
    WinLoseResult: {
      type: String,
    },
    params: {
      type: Object,
    },
    isStatusWidthAuto: {
      type: Boolean,
      default: false,
    },
    isShowStatusAtTop: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },

  watch: {
    params: {
      deep: true,
      handler(newVal, oldVal) {
        if (typeof newVal.leagueKey !== "undefined") {
          this.getFixtureByLeagueData(newVal);
        } else {
          this.getMatchListData(newVal);
        }
      },
    },
	$route() {

	},
  },

  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      cnLocaleList: config.cnLocaleList,
      isLoading: false,
      isShowNoData: false,
      path: [],

      matchList: [],
      tmpMatchList: [],

      //odds info
      oddsMatch: {
        isShow: false,
        list: [], // store initial odds list
        isShowDetail: false,
        detailStyle: {},
        detailList: {}, //store current hover display odds detail list
        isShowBriefData: false,
      },

      currentMobileSelectedMatch: null,
      isShowOddsDetailMobile: false,
      count: 2,
      selectedMatchId: "",

      displayDateFormat: config.displayDateFormat,
      countryKey: "",
      leagueKey: "",

      countryId: this.$route.params.countryId,//config.country.brazil.countryId
    };
  },

  computed: {
    ...mapGetters([
      "isMobile",
      "currentLocale",
      "currentLanguageObj",
      "currentSportType",
      "isLogin",
	  "currentPathName"
    ]),
    matchList() {
      //filter for matchList if score ==="", then show "?"
      let matchList = this.matchList;
      if (this.type === "fav") {
        if (this.cnLocaleList.includes(this.currentLocale)) {
          matchList.forEach(x => {
            let dateArr = x.matchDate.split(" ");
            dateArr[0] = dateArr[0] + "日";
            dateArr[2] = dateArr[2] + "年";
            x.matchDate = dateArr.join(" ");
          });
        }
      } else {
        for (let level1 = 0; level1 < matchList.length; level1++) {
          for ( let level2 = 0; level2 < matchList[level1].matchList.length; level2++) {
            if (this.type === "team") {
              let matchListLevel2 = matchList[level1].matchList[level2];
              let dateTimePattern = /\d{2}\-[a-zA-Z]{3}\-\d{4} \d{2}\:\d{2}/;
              if (dateTimePattern.test(matchListLevel2.matchStatus) && !this.isMobile) {
                let classNames = !this.isMobile ? 'display-flex-center flex-col' : 'display-flex-se';
                let matchDate = matchListLevel2.matchDate.split("-");//matchListLevel2.matchStatus.split("-")
                let matchDateTime = `<div class='${classNames}'><div>${matchListLevel2.matchTime}</div><div>${matchDate[2]}.${matchDate[1]}.${matchDate[0].substring(2)}</div></div>`;
                // `<div class='${classNames}'><div>${matchListLevel2.matchTime}</div><div>${matchDate[0]} ${matchDate[1]}</div></div>`
                matchListLevel2.matchStatus = matchDateTime;
              }
            }
            for (let level3 = 0; level3 < matchList[level1].matchList[level2].opponents.length; level3++) {
              if (matchList[level1].matchList[level2].opponents[level3].score === "") {
                matchList[level1].matchList[level2].opponents[level3].score ="-";
              }
            }
          }
        }
      }
      return matchList;
    },
  },
  created() {
    this.path = this.$route.path;
  },

  beforeMount() {
    //window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
	// this.currentPathName = window.location.pathname;
  },

  methods: {
    ...mapActions([
		"getMatchListAsync4",
		"getFixtureByLeague",
		"getMatchListByTeam",
		"getFavMatchList",
		"addFavMatch",
		"unFavMatch",
		"toastrFavChangeEvent",
		"favLoginTipsChangeEvent",
    ]),

    replaceByDefault(e) {
		e.target.src = `${this.s3ImgUrl}/team/default.png`;
    },

    async getFavMatchListData(newVal) {
      if (!newVal.isCallInterval) {
        this.isLoading = true;
        this.matchList = [];
      } else {
        this.isLoading = false;
      }

      const theDate = moment(newVal.date).startOf("day");
      const today = moment().startOf("day");
      const diffDay = theDate.diff(today, "days");
      let isPreviousDate = diffDay < 0 ? true : false;

      const result = await this.getFavMatchList(newVal);

      if (result.result === null) {
        this.isShowNoData = true;
        this.$emit("isDataReturn", false);
      } else {
        if (result.result.matchDateList.length === 0) {
          this.isShowNoData = true;
          this.$emit("isDataReturn", false);
        } else {
			this.isShowNoData = false;
			this.$emit("isDataReturn", true);

			this.matchList = result.result.matchDateList;
			//sort by date
			this.matchList.sort((x,y) => {
				return  moment(x.matchDate) - moment(y.matchDate);				
			})

			this.matchList.forEach(element => {
				element.matchDate = moment(element.matchDate).format(this.displayDateFormat)
       
			});

          	this.oddsMatch.list = result.result.oddsListMatchMap;

			//only live, today and and future day show 6 data at match odds overview
			if (newVal.isLiveOnly) {
				this.oddsMatch.isShowBriefData = false;
			} else if (isPreviousDate) {
				this.oddsMatch.isShowBriefData = true;
			} else {
				this.oddsMatch.isShowBriefData = false;
			}

			//mapping odds value to match list
			for (let i = 0; i < this.matchList.length; i++) {
				let level1 = this.matchList[i].leagueMatchList;
				for (let y = 0; y < level1.length; y++) {
				let level2 = level1[y].matchList;
				if (!newVal.isCallInterval) {
					level2.isShowOddsDetail = false;
				}

				for (let x = 0; x < level2.length; x++) {
					level2[x].oddsMatchOverview = this.oddsMatch.list[
					level2[x].matchId
					];
				}
				}
			}
          // setTimeout(() => {
          //     this.$emit("changeMatchContentEvent", this.matchList); //broacast event to those who subscribe it
          // }, 100);
        }
      }

      this.isLoading = false;
    },

    async getMatchListData(newVal) {
		this.haha = "s"
		let params;
		if (this.type === 'league') {
			params = newVal;
		} else {
			params = {
				leagueIdList: newVal.leagueIdList ? newVal.leagueIdList.toString() : "",
				matchDate: newVal.date,
				liveOnly: newVal.isLiveOnly,
				lang: this.currentLanguageObj.apiParam,
				timeZone: this.$tools.getCurrentTimeZone(),
        countryId: this.countryId,
			};
		}
    params.countryId = this.$route.meta.countryId;

    this.isShowNoData = false;

		if (newVal.isCallInterval) {
			this.isLoading = false;
		} else {
			this.isLoading = true;
			this.matchList = [];
		}
  
		const theDate = moment(params.matchDate).startOf("day");
		const today = moment().startOf("day");
		const diffDay = theDate.diff(today, "days");

		let isPreviousDate = diffDay < 0 ? true : false;
		let result, apiList;

		if (this.type === 'league') {
			result = await this.getFixtureByLeague(params);
      this.countryKey = result.result.countryKey;
      this.leagueKey = result.result.leagueKey;
			apiList= "matchDateList";
      this.isLoading = false;
		} else {
			result = await this.getMatchListAsync4(params);;
			apiList= "leagueMatchList";
      this.isLoading = false;
		}

      if (
        (this.type === 'league' && (result.result.length === 0 || result.result[apiList].length === 0)) ||
        (this.type !== 'league' && result.result === null)// homepage
      ) {
        this.isShowNoData = true;
        if (this.type === 'league') {
          this.$emit("changeMatchContentEvent", result.result); //broacast event to those who subscribe it
        }
      } else {
        this.isShowNoData = false;
        setTimeout(() => {}, 300);
    
        this.matchList = result.result[apiList];
    

        //match list sort by fav
        this.matchList.sort((x,y) => {
          return y.isFavLeague - x.isFavLeague;				
        })

        if (this.type === 'league') {
          this.oddsMatch.list = result.result.oddListMatchMap;
        } else {
          this.oddsMatch.list = result.result.oddsListMatchMap;
        }
        

        //only live, today and and future day show 6 data at match odds overview
        if (newVal.isLiveOnly) {
        this.oddsMatch.isShowBriefData = false;
        } else if (isPreviousDate) {
          this.oddsMatch.isShowBriefData = true;
        } else {
          this.oddsMatch.isShowBriefData = false;
        }

        //mapping odds value to match list
        for (let i = 0; i < this.matchList.length; i++) {
          let level1 = this.matchList[i].matchList;

          if (!newVal.isCallInterval) {
            level1.isShowOddsDetail = false;
          }
    
          for (let x = 0; x < level1.length; x++) {
            if (this.type === 'league') {
              const theDate = moment(level1[x].matchDate).startOf("day");
              const today = moment().startOf("day");
              const diffDay = theDate.diff(today, "days");
              const isPreviousDate = diffDay < 0 ? true : false;
              //only today and and future day show 6 data at match odds overview
              if (isPreviousDate) {
                level1[x].oddsMatchShowBriefData = true;
              } else {
                level1[x].oddsMatchShowBriefData = false;
              }
            }

            level1[x].oddsMatchOverview = this.oddsMatch.list[level1[x].matchId];
          }
        }
        let gg = this.matchList;
  
  
        setTimeout(() => {
          this.$emit("changeMatchContentEvent", result.result); //broacast event to those who subscribe it
        }, 100);
      }

      // this.isLoading = false;
    },

    async getMatchListByTeamData(newVal) {
      let params = {
        teamId: newVal.teamId,
        timeZone: this.$tools.getCurrentTimeZone(),
      };

      if (!newVal.isCallInterval) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }

      const result = await this.getMatchListByTeam(params);
      this.isLoading = false;

      if (result.result === null) {
        this.isShowNoData = true;
      } else {
        if (result.result.leagueMatchList.length === 0) {
          this.isShowNoData = true;
        } else {
          this.isShowNoData = false;

          this.matchList = result.result.leagueMatchList;

          //match list sort by fav
          this.matchList.sort((x,y) => {
            return y.isFavLeague - x.isFavLeague;				
          })

          this.oddsMatch.list = result.result.oddsListMatchMap;

          //mapping odds value to match list
          for (let i = 0; i < this.matchList.length; i++) {
            let level1 = this.matchList[i].matchList;
            
            if (!newVal.isCallInterval) {
              level1.isShowOddsDetail = false;
            }
      
            for (let x = 0; x < level1.length; x++) {
              const theDate = moment(level1[x].matchDate).startOf("day");
              const today = moment().startOf("day");
              const diffDay = theDate.diff(today, "days");
              const isPreviousDate = diffDay < 0 ? true : false;
              level1[x].oddsMatchOverview = this.oddsMatch.list[level1[x].matchId];
              //only today and and future day show 6 data at match odds overview
              if (isPreviousDate) {
                level1[x].oddsMatchShowBriefData = true;
              } else {
                level1[x].oddsMatchShowBriefData = false;
              }
            }
          }

          this.$emit("changeMatchContentEvent", this.matchList);
        }
      }

    },

    async getFixtureByLeagueData(newVal) {
      let params = {
        leagueKey: newVal.leagueKey,
        matchDate: newVal.matchDate,
        timeZone: this.$tools.getCurrentTimeZone(),
      };
      this.isLoading = true;
      const result = await this.getFixtureByLeague(params);
      this.isLoading = false;
      this.matchList = result.result.matchList;

      if (this.matchList.length == 0) {
        this.isShowNoData = true;
      } else {
        this.isShowNoData = false;
      }

      this.$emit("changeMatchContentFixtureByLeagueEvent", this.matchList);
    },
    handleMatchDetailRoutePath(o, level2, e) {
      // if (level2.containLiveStream) {
      //     e.preventDefault();
      //     e.stopPropagation();
      //     let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;
      //     window.open(link);
      //     return false
      // } else {
      //     this.$tools.openNewWindow(e)
      // }
    },
    //redirect to highlightpage if the match contain highlight video
    handleHighlight(o, level2, e) {
      if (level2.containLiveStream) {
        e.preventDefault();
        e.stopPropagation();

        let link = `/${this.currentLocale}/${this.currentSportType}/${
          o.countryKey
        }/${o.leagueKey}/${level2.opponents[0].name.split(" ").join("-") +
          "-vs-" +
          level2.opponents[1].name.split(" ").join("-")}/${level2.matchId}`;
        window.open(link);
        return false;
      } else if (level2.containHighlight) {
        e.preventDefault();
        e.stopPropagation();

        this.$router.push({
          path: `/${this.currentLocale}/${this.currentSportType}/highlights/${
            o.countryKey
          }/${o.leagueKey}/${level2.opponents[0].name.split(" ").join("-") +
            "-vs-" +
            level2.opponents[1].name.split(" ").join("-")}/${level2.matchId}`,
          query: {
            date: level2.matchDate,
          },
        });
        return false;
      } else {
        e.preventDefault();
        e.stopPropagation();

        let link = `/${this.currentLocale}/${this.currentSportType}/${
          o.countryKey
        }/${o.leagueKey}/${level2.opponents[0].name.split(" ").join("-") +
          "-vs-" +
          level2.opponents[1].name.split(" ").join("-")}/${level2.matchId}`;
        window.open(link);
        return false;
      }
    },
    handleRedirect(o, level2, e) {
      e.preventDefault();
      e.stopPropagation();
      let team1 = level2.opponents[0].name.split(" ").join("-").replace(/\//g,"-");
      let team2 = level2.opponents[1].name.split(" ").join("-").replace(/\//g,"-");
      let link = `/${this.currentLocale}/${this.currentSportType}/${
        o.countryKey
      }/${o.leagueKey}/${team1 +
        "-vs-" +
        team2}/${level2.matchId}/stats`;
      window.open(link,"_self");
      return false;
    },
    handleRedirectMobile() {
      let link = `/${this.currentLocale}/${this.currentSportType}/${this.currentMobileSelectedMatch.parent.countryKey}/${this.currentMobileSelectedMatch.parent.leagueKey}/${this.currentMobileSelectedMatch.child.matchName}/${this.currentMobileSelectedMatch.child.matchId}/stats`;
      window.open(link,"_self");
    },
    selectFavMatch(o) {
      o.fav = !o.fav;
    },

    handleScroll() {
      let element = this.$refs.scrollComponent;

      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        this.loadMorePosts();
      }
    },

    loadMorePosts() {
      if (this.tmpMatchList.length > 0) {
        let gg = this.count + 2;

        for (let i = this.count; i < gg; i++) {
          this.matchList.push(this.tmpMatchList[i]);
        }
        this.count = this.count + 2;
        if (this.count < this.tmpMatchList.length + 2) {
        }
      }
    },

    handleShowOddsDetailHover(o, level2, event) {
      if (
        this.oddsMatch.list[level2.matchId] &&
        !this.oddsMatch.isShowBriefData
      ) {
        level2.isShowOddsDetail = true;
        this.oddsMatch.data = level2;
        this.oddsMatch.detailList = this.oddsMatch.list[level2.matchId];
      }
    },

    handleShowOddsDetailLeave(o, level2) {
      level2.isShowOddsDetail = false;
    },

    handleShowOddsDetailMobile(o, level2, e) {
      this.currentMobileSelectedMatch = {
        parent: o,
        child: level2,
      };
      e.preventDefault();
      e.stopPropagation();
      if (
        (
          this.oddsMatch.list[level2.matchId] &&
          !this.oddsMatch.isShowBriefData &&
          (this.type !== 'league' && this.type !== 'team')
        ) || (
          this.oddsMatch.list[level2.matchId] &&
          !level2.oddsMatchShowBriefData &&
          (this.type === 'league' || this.type === 'team')
        )
      ) {
        this.oddsMatch.data = level2;
        this.oddsMatch.detailList = this.oddsMatch.list[level2.matchId];
        this.$refs.modalPopupRef.handleOpen();
      }
    },

    // toggleStatsPopover(level2) {
    //     this.matchList.forEach(leagueList=> {
    //         leagueList.matchList.forEach(match => {
    //             match.isShowStatsPopover = false;
    //         });
    //     });
    //     level2.isShowStatsPopover = !level2.isShowStatsPopover;
    // },
    showStatsPopover(level2) {
      level2.isShowStatsPopover = true;
    },
    hideStatsPopover(level2) {
      level2.isShowStatsPopover = false;
    },
    showStatsPopoverMobile(level2, e) {
      e.preventDefault();
      e.stopPropagation();
      this.selectedMatchId = level2.matchId;
      this.$refs.statsTableModalPopupRef.handleOpen();
    },

    isMatchEnded(match) {
      return match.matchState < 0 ? true : false;
    },
    
    favMatchClassObj(o) {
      // const matchEndedStatus = config.matchEndedStatus;
      return {
        disable: 
			this.isLogin
			? this.isMatchEnded(o) // matchEndedStatus.includes(o.matchStatus)
			: false,
        selected: o.isFavMatch,
      };
    },

    async handleFavMatchClick(o, level2, event) {
      event.preventDefault();
	    event.stopPropagation();

      if (this.isLogin) {
        // config.matchEndedStatus.includes(level2.matchStatus)
        if (this.isMatchEnded(level2)) {
          return;
        } else {
          level2.isFavMatch = !level2.isFavMatch;
        }

        let params = {
          matchId: level2.matchId,
        };

        const result = level2.isFavMatch ? await this.addFavMatch(params) : await this.unFavMatch(params);

        if (result.result.businessCode === -1) {
          level2.isFavMatch ? level2.isFavMatch = false : level2.isFavMatch = true
        } else {
          let paramsToastr = {
            isOpen: true,
            msg: this.$i18n.t("MATCH"),
            isAddedFav: level2.isFavMatch,
          };

          //update fav list
          if (this.type === 'fav') {
            this.updateFavMatchList(level2)
          }
          
          this.toastrFavChangeEvent(paramsToastr);	
        }
      } else {
        if (this.isMobile) {
          this.$refs.PopUpSignInIndexMobileRef.handleOpen();
        } else {
          this.favLoginTipsChangeEvent({ isOpen: true, event: event, callBackUrl: this.currentPathName });
        }
        
      }
    },

	updateFavMatchList(o) {
		let currentMatchList = this.matchList;

		for(let i = 0; i < currentMatchList.length; i++) {
			let level1 = currentMatchList[i].leagueMatchList;

			for(let x = 0; x < level1.length; x++) {
				let level2 = level1[x].matchList;

				for(let y = 0; y < level2.length; y++) {
					if (level2[y].matchId === o.matchId) {
						level2.splice(y,1);

						if (level2.length === 0) {
							level1.splice(x,1);

							if (level1.length === 0) {
								currentMatchList.splice(i,1);
								this.$emit("favChangeEmit");							
							}
						}
					}
				}
			}
		}			
	}
  },
};
</script>

<style scoped>

.skeleton-match__wrapper {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.skeleton-match__title {
  width: 50%;
  height: 1rem;
  margin-bottom: 0.25rem;
}

.skeleton-match__content {
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.home-match__win-lose {
  background-color: green;
  border-radius: 50%;
  padding: 0.3rem;
  color: white;
  width: 0.75rem;
  border: 0.1rem solid white;
  height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
}

.home-match__win-lose.W {
  background-color: #5cd66c;
}

.home-match__win-lose.L {
  background-color: #cf283e;
}

.home-match__win-lose.D {
  background-color: #ed9c5a;
}

.home-match__status {
  margin-right: 0.5rem;
  width: 2.25rem;/*1.6rem   2rem*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.563rem;
  color: var(--color-grey-light);
}

.home-match__1-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-match__1-container {
  justify-content: space-between;
  /* margin-bottom: 0.2rem; */
}
.home-match__team-name {
  color: var(--color-grey-light);
}
.home-match__date{
	font-size: 0.875rem;
	margin-bottom: .5rem;
}
.match-wrapper {
  margin-bottom: 1.2rem;
}

.match-container {
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
}

.match-container:hover .home-match__score-card__wrapper {
  background-color: var(--bg-hover);
}

.match-container:hover .home-match__static-card__wrapper {
  background-color: var(--bg-hover);
}

.match-container:hover
  .home-match__actions-card__wrapper
  .home-match__icon-wrapper {
  background-color: var(--bg-hover);
}

.home-match__static-card__wrapper {
  background-color: #35454d;
  padding: 0.75rem 0.25rem 0.75rem;/*0.5rem 0.25rem 0.2rem*/
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 6.25rem;
  align-items: center;
}

.home-match__static-card__brief {
  padding: 0.5rem 1.5rem;
}

.home-match__score-card__wrapper {
  display: flex;
  background-color: var(--color-theme);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  width: 35rem;
  padding: 0.5rem 0;
  border-left: 0.4rem solid transparent;
}

.home-match__score-card__wrapper.no-odds-match {
  border-radius: 0.5rem;
}

.home-match__actions-card__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-selected {
  border-left: 0.4rem solid var(--bg-selected);
}

.match-status__live {
  background-color: #cf283e;
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-size: 0.65rem;
}

.match-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.match-detail__status {
  width: 1.6rem;
}

.match-detail__status-w-auto {
  width: auto;
}

.match-detail__score-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-detail__score-wrapper .score {
  min-width: 1.25rem;
  min-height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.688rem;
  font-weight: 700;
}

.match-detail__name {
  width: 9rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.match-middle {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.match-middle__seperator {
  margin: 0 0.5rem;
  font-weight: bold;
  font-weight: bold;
}
.has-subleague-fav{
	margin-right: .25rem;
}
.penalty-compact-label {
  border-radius: 4px;
  background: var(--color-ok);
  padding: 2px 4px;
  font-size: 0.563rem;
}
.penalty-score {
  border-left: 1px solid var(--color-grey-40);
}
.capitalize {
  text-transform: capitalize;
}

@media (max-width: 400px) {
  .home-match__team-name {
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 9.5rem;
  }
  .home-match__team-name.with-fav-star,
  .home-match__team-name.with-penalty-score {
    max-width: 8.5rem;
  }
  .home-match__team-name.with-fav-star.with-penalty-score,
  .home-match__team-name.with-penalty-score.with-penalty-win {
    max-width: 7.5rem;
  }
  .home-match__team-name.with-fav-star.with-penalty-score.with-penalty-win {
    max-width: 6.5rem;
  }
}

@media (max-width: 320px) {
  .home-match__team-name {
    max-width: 8rem;
  }
  .home-match__team-name.with-fav-star,
  .home-match__team-name.with-penalty-score {
    max-width: 7rem;
  }
  .home-match__team-name.with-fav-star.with-penalty-score,
  .home-match__team-name.with-penalty-score.with-penalty-win {
    max-width: 6rem;
  }
  .home-match__team-name.with-fav-star.with-penalty-score.with-penalty-win {
    max-width: 5rem;
  }
}
</style>
